import React from "react";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import ForbiddenScreen from "../pages/Forbidden/Forbidden";

export const RolePolicy = {
  MATCH_ANY: "MATCH_ANY",
  MATCH_ALL: "MATCH_ALL",
};

export const Protected = ({ roles, children, policy = RolePolicy.MATCH_ANY, route = false }) => {
  const {
    keycloak: { token, authenticated, login },
  } = useKeycloak();
  if (!authenticated || !token) login();

  const payload = decodeJwt(token);

  if (!roles || roles.length === 0) return children;
  else {
    if (!payload.realm_access?.roles) return route ? <ForbiddenScreen /> : <></>;

    let hasPermission = false;
    if (policy === RolePolicy.MATCH_ANY) hasPermission = roles?.some((role) => payload.realm_access.roles.includes(role));
    if (policy === RolePolicy.MATCH_ALL) hasPermission = roles?.every((role) => payload.realm_access.roles.includes(role));

    if (hasPermission) return children;
    else return route ? <ForbiddenScreen /> : <></>;
  }
};
