import React from "react";
import Icon from "@ant-design/icons";


export const EinfIcon = props => (
  <Icon
    {...props}
    component={() =>
      <svg viewBox="0 0 400 500">
        <path style={{ fill: props.color }} d="M 397.623 250.142 L 397.623 428.925 C 399.506 467.701 367.556 499.651 328.78 497.768 C 242.349 497.768 155.917 497.768 69.486 497.768 C 31.391 499.144 0.118 467.93 1.421 429.833 C 1.421 331.56 1.421 233.288 1.421 135.015 C 0.749 109.568 11.904 85.246 31.628 69.154 C 50.644 53.078 69.875 37.131 89.322 21.314 C 103.519 8.935 121.696 2.078 140.532 1.996 L 330.984 1.996 C 367.978 1.408 398.141 31.511 397.623 68.507 C 397.754 129.44 397.623 189.855 397.623 250.142 Z M 364.693 250.142 L 364.693 73.044 C 367.23 51.475 349.08 33.134 327.484 35.446 C 266.204 35.446 204.795 35.446 143.255 35.446 C 130.491 35.444 118.154 40.047 108.509 48.411 C 91.137 63.06 73.765 77.711 56.651 91.712 C 42.171 102.533 33.971 119.807 34.74 137.867 C 34.74 234.153 34.74 330.393 34.74 426.592 C 31.837 449.167 51.06 468.39 73.635 465.487 L 324.892 465.487 C 351.988 465.487 364.693 452.521 364.693 425.685 L 364.693 250.142 Z" />
        <path style={{ fill: props.color }} d="M 200.819 316.392 L 104.102 316.392 C 91.531 318.006 81.925 305.407 86.812 293.711 C 87.055 293.133 87.329 292.566 87.636 292.018 C 91.176 285.836 98.065 282.365 105.139 283.203 L 297.018 283.203 C 308.945 283.203 317.113 290.074 316.983 300.056 C 316.536 310.145 307.552 317.693 297.536 316.392 L 200.819 316.392 Z" />
        <path style={{ fill: props.color }} d="M 199.133 398.718 L 101.638 398.718 C 89.971 398.718 82.322 392.365 82.191 382.512 C 82.261 372.374 91.198 364.592 101.249 365.917 L 298.573 365.917 C 311.299 364.799 320.463 377.875 315.069 389.456 C 313.352 393.141 310.343 396.067 306.611 397.681 C 303.659 398.711 300.523 399.108 297.406 398.848 L 199.133 398.718 Z" />
        <path style={{ fill: props.color }} d="M 281.848 167.686 C 281.848 183.374 281.848 199.191 281.848 214.878 C 281.848 226.545 275.756 233.288 265.772 233.417 C 255.789 233.547 249.178 226.417 249.178 214.489 L 249.178 118.679 C 248.029 108.898 255.538 100.248 265.383 100.011 C 275.495 99.731 283.39 108.681 281.848 118.679 C 281.978 134.627 281.848 151.221 281.848 167.686 Z" />
        <path style={{ fill: props.color }} d="M 182.797 184.799 C 182.797 174.557 182.797 164.185 182.797 153.814 C 181.666 140.989 194.84 131.748 206.514 137.179 C 212.897 140.15 216.737 146.8 216.117 153.814 C 216.117 174.47 216.117 195.085 216.117 215.656 C 217.249 228.48 204.074 237.721 192.402 232.291 C 186.019 229.32 182.179 222.669 182.797 215.656 C 182.669 204.895 182.797 194.913 182.797 184.799 Z" />
        <path style={{ fill: props.color }} d="M 149.868 192.06 C 149.868 200.097 149.868 208.007 149.868 216.045 C 150.476 225.572 142.818 233.589 133.272 233.417 C 123.997 233.584 116.538 225.826 117.066 216.564 C 117.066 199.58 117.066 182.595 117.066 165.612 C 116.795 156.133 124.866 148.548 134.31 149.406 C 143.241 149.547 150.344 156.942 150.127 165.872 C 149.997 174.557 149.868 183.503 149.868 192.06 Z" />
      </svg>
    }
  />
);
