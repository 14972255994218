import React, { Suspense } from "react";
import { ConfigProvider } from "antd";
import { Router } from "./routes/Router";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak } from "./configs/keycloak";
import { InitializingApp } from "./components/InitializingApp";
import { updateStorageItem } from "./helpers/local-storage";
import { useTranslation } from "react-i18next";
import esES from "antd/es/locale/es_ES";
import enUS from "antd/es/locale/en_US";


const App = () => {
  const { i18n } = useTranslation();
  const language = i18n.language?.substring(0, 2) || "es";

  const onKeycloakEvent = (event) => {
    if (!keycloak.authenticated) keycloak.login();
    if (event === "onAuthError" || event === "onAuthRefreshError" || event === "onAuthLogout") keycloak.login();
  };
  const onKeycloakTokens = (tokens) => {
    if (tokens?.token) updateStorageItem("KC_TOKEN", JSON.stringify(tokens));
    else keycloak.login();
  };
  
  return (
    <ConfigProvider locale={language === "es" ? esES : enUS}>
      <ReactKeycloakProvider
        authClient={keycloak}
        initOptions={{ onload: "check-sso" }}
        onEvent={onKeycloakEvent}
        onTokens={onKeycloakTokens}
        LoadingComponent={<InitializingApp />}>
        <Suspense fallback={<InitializingApp />}>
          <Router />
        </Suspense>
      </ReactKeycloakProvider>
    </ConfigProvider>
  );
};
export default App;
