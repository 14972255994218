import React from "react";
import Icon from "@ant-design/icons";


export const LogoutIcon = props => (
  <Icon
    {...props}
    component={() =>
      <svg viewBox="0 0 370 370">
        <path style={{ fill: props.color }} d="M 260.197 25.505 L 169.121 25.505 C 153.972 25.505 146.961 32.575 146.961 47.843 L 146.961 109.987 C 147.47 114.52 145.634 119 142.089 121.869 C 138.684 124.541 134.016 124.937 130.208 122.879 C 126.114 120.647 123.769 116.168 124.266 111.531 C 124.266 89.728 124.266 67.864 124.266 46.18 C 123.108 21.604 143.421 1.399 167.992 2.692 L 335.41 2.692 C 353.244 1.63 368.119 16.148 367.492 34.001 C 367.492 124.701 367.492 215.321 367.492 305.863 C 367.979 319.641 359.085 332.006 345.866 335.924 C 315.924 346.023 285.981 355.925 256.038 365.63 C 236.477 372.5 215.948 358.138 215.698 337.408 C 215.698 332.36 215.698 327.25 215.698 321.664 L 209.757 321.664 C 194.906 321.664 180.052 321.664 165.259 321.664 C 144.763 321.308 125.929 305.09 125.098 285.425 C 124.087 260.592 124.622 235.639 124.682 210.805 C 124.537 206.027 127.802 201.815 132.465 200.765 C 140.664 198.211 147.317 203.735 147.317 213.479 C 147.317 235.283 147.317 257.146 147.317 278.83 C 146.481 290.167 155.949 299.578 167.28 298.673 C 182.132 298.673 196.984 298.673 211.718 298.673 C 212.967 298.673 214.45 299.149 215.64 297.604 L 215.64 66.974 C 215.64 49.151 222.649 39.17 239.403 33.526 L 260.553 26.336 L 260.197 25.505 Z M 344.44 36.497 C 344.44 26.753 340.342 23.724 331.132 26.813 L 245.641 55.27 C 240.818 56.306 237.56 60.823 238.097 65.726 C 238.097 146.999 238.097 228.193 238.097 309.307 C 238.097 318.042 238.097 326.774 238.097 335.507 C 238.097 342.934 242.136 345.905 249.266 343.588 C 278.851 334.2 308.081 324.517 337.786 314.655 C 342.502 313.229 345.427 308.524 344.618 303.664 C 344.618 259.047 344.618 214.55 344.618 169.813 L 344.44 36.497 Z" />
        <path style={{ fill: props.color }} d="M 40.735 150.921 L 75.907 116.165 C 77.035 115.137 78.107 114.046 79.114 112.897 C 83.219 108.568 83.219 101.782 79.114 97.452 C 74.91 92.861 67.756 92.619 63.252 96.917 C 56.599 103.155 50.3 109.749 43.825 116.165 C 31.468 128.523 19.11 140.821 6.812 153.236 C 1.049 157.78 1.049 166.517 6.812 171.062 C 25.17 189.478 43.647 207.835 62.004 226.254 C 64.859 229.774 69.468 231.363 73.886 230.351 C 82.409 228.442 85.671 218.021 79.755 211.594 C 79.46 211.275 79.146 210.972 78.817 210.687 C 68.243 199.816 57.43 189.122 46.617 178.428 C 45.165 177.282 43.615 176.267 41.983 175.398 L 43.113 173.674 L 156.288 173.674 C 166.091 173.674 171.853 166.545 168.764 158.347 C 166.777 153.204 161.512 150.104 156.051 150.862 C 127.177 150.862 98.304 150.862 69.431 150.862 L 40.735 150.921 Z" />
      </svg>
    }
  />
);
