import React, { lazy, Suspense } from "react";
import { useRoutes, BrowserRouter } from "react-router-dom";
import { Spin } from "antd";
import { NotFound } from "../pages/NotFound";
import { AppLayout } from "../layout/AppLayout";
import { Roles } from "../constants/CommonEnum";
import { Protected } from "./Protected";
import HomeScreen from "../pages/Home/HomeScreen";

const OrganizacionScreen = lazy(() => import("../pages/Organization/OrganizationScreen"));
const MyOrganizacionScreen = lazy(() => import("../pages/Organization/MyOrganizationScreen"));
const ConversionFactorScreen = lazy(() => import("../pages/ConversionFactor/ConversionFactorScreen"));
const UserScreen = lazy(() => import("../pages/User/UserScreen"));
const MyUserScreen = lazy(() => import("../pages/User/MyUserScreen"));
const EinfScreen = lazy(() => import("../pages/Einf/EinfScreen"));
const MyEinfScreen = lazy(() => import("../pages/Einf/MyEinfScreen"));
const SRScreen = lazy(() => import("../pages/SustainabilityReporting/SustainabilityReportingScreen"));
const MySRScreen = lazy(() => import("../pages/SustainabilityReporting/MySustainabilityReportingScreen"));
const LicenseScreen = lazy(() => import("../pages/License/LicenseScreen"));
const ForbiddenScreen = lazy(() => import("../pages/Forbidden/Forbidden"));
const TemplateScreen = lazy(() => import("../pages/Template/TemplateScreen"));
const DoubleMaterialityReportScreen = lazy(() => import("../pages/DoubleMateriality/DoubleMaterialityScreen"));
const MyDoubleMaterialityReportScreen = lazy(() => import("../pages/DoubleMateriality/MyDoubleMaterialityScreen"));

const routesArray = [
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "forbidden",
    element: <ForbiddenScreen />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "einf/*",
    element: <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]} children={<EinfScreen />} />,
  },
  {
    path: "my-einf/*",
    element: <Protected route roles={[Roles.ADMIN_ORGANIZATION, Roles.USER_ORGANIZATION]} children={<MyEinfScreen />} />,
  },
  {
    path: "sr/:templateId/*",
    element: <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]} children={<SRScreen />} />,
  },
  {
    path: "my-sr/:templateId/*",
    element: <Protected route roles={[Roles.ADMIN_ORGANIZATION, Roles.USER_ORGANIZATION]} children={<MySRScreen />} />,
  },
  {
    path: "dm/*",
    element: <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]} children={<DoubleMaterialityReportScreen />} />,
  },
  {
    path: "my-dm/*",
    element: <Protected route roles={[Roles.ADMIN_ORGANIZATION, Roles.USER_ORGANIZATION]} children={<MyDoubleMaterialityReportScreen />} />,
  },
  {
    path: "organization/*",
    element: <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]} children={<OrganizacionScreen />} />,
  },
  {
    path: "my-organization/*",
    element: <Protected route roles={[Roles.ADMIN_ORGANIZATION]} children={<MyOrganizacionScreen />} />,
  },
  {
    path: "conversion-factor",
    element: <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]} children={<ConversionFactorScreen />} />,
  },
  {
    path: "template/*",
    element: <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]} children={<TemplateScreen />} />,
  },
  {
    path: "user/*",
    element: <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]} children={<UserScreen />} />,
  },
  {
    path: "my-user/*",
    element: <Protected route roles={[Roles.ADMIN_ORGANIZATION]} children={<MyUserScreen />} />,
  },
  {
    path: "license/:id",
    element: <Protected route roles={[Roles.SUPERADMIN, Roles.ADMIN_CROWE]} children={<LicenseScreen />} />,
  },
];

const AppRoutes = () => {
  const routes = useRoutes(routesArray);
  return routes;
};

export const Router = () => (
  <BrowserRouter basename={`${process.env.REACT_APP_PUBLIC_URL}`}>
    <AppLayout>
      <Suspense fallback={<Spin spinning />}>
        <AppRoutes />
      </Suspense>
    </AppLayout>
  </BrowserRouter>
);
