import React from "react";
import Icon from "@ant-design/icons";


export const UsersIcon = props => (
  <Icon
    {...props}
    component={() =>
      <svg viewBox="0 0 255 305">
        <path style={{ fill: props.color }} d="M 127.305 302.864 L 55.804 302.864 C 41.907 303.477 28.292 298.757 17.761 289.663 C 7.97 280.826 2.351 268.285 2.269 255.103 C 0.572 228.803 5.752 202.513 17.302 178.834 C 26.259 160.18 44.931 148.135 65.612 147.666 C 70.14 147.502 74.604 148.785 78.353 151.333 C 87.987 158.255 98.539 163.81 109.704 167.835 C 127.728 173.014 147.096 170.346 163.056 160.501 C 166.585 158.42 169.951 156.091 173.14 153.534 C 181.427 147.32 192.297 145.733 202.016 149.316 C 217.818 153.389 231.048 164.168 238.224 178.834 C 247.327 197.067 252.213 217.116 252.525 237.502 C 253.57 247.293 253.039 257.203 250.966 266.837 C 245.925 285.609 230.01 299.453 210.724 301.855 C 192.39 303.688 174.056 302.312 155.722 302.68 C 146.097 303.046 136.472 302.864 127.305 302.864 Z M 127.305 285.079 C 152.88 285.079 178.365 285.079 203.849 285.079 C 218.919 285.437 231.974 274.712 234.559 259.871 C 235.52 252.105 235.52 244.25 234.559 236.494 C 234.054 221.791 230.946 207.296 225.391 193.684 C 221.586 182.42 213.337 173.214 202.565 168.2 C 195.387 163.929 186.284 164.7 179.923 170.126 C 177.751 171.914 175.386 173.446 172.864 174.709 C 154.935 186.187 133.308 190.449 112.364 186.626 C 97.898 183.574 84.294 177.303 72.579 168.291 C 68.122 165.075 62.385 164.223 57.178 166 C 43.098 170.208 32.161 181.355 28.211 195.519 C 21.565 214.265 18.64 234.13 19.594 254.004 C 19.612 258.55 20.676 263.033 22.711 267.112 C 28.063 278.333 39.632 285.235 52.045 284.621 C 77.344 285.172 102.372 285.079 127.305 285.079 Z" />
        <path style={{ fill: props.color }} d="M 197.982 77.999 C 195.287 133.678 133.327 165.562 86.456 135.392 C 39.577 105.225 42.942 35.62 92.507 10.108 C 104.36 4.002 117.671 1.3 130.971 2.278 C 170.197 5.068 199.981 38.718 197.982 77.999 Z M 125.655 19.972 C 83.313 19.972 56.858 65.806 78.022 102.473 C 99.199 139.142 152.119 139.132 173.295 102.465 C 178.208 93.95 180.758 84.259 180.656 74.423 C 180.921 44.035 156.034 19.44 125.655 20.063 L 125.655 19.972 Z" />
      </svg>
    }
  />
);
