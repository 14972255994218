import React from "react";
import { Col, Grid, Row, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { useBreakpoint } = Grid;

const HomeScreen = () => {
  const { xs, xl } = useBreakpoint();
  const { t } = useTranslation("translation");

  return (
    <Row justify="center" align="middle" style={{ height: '100%' }}>
      <Col span={24} align="center">
        <Title style={{ fontSize: xs ? '48px' : xl ? '80px' : '64px' }}>
          {t("common.title")}
        </Title>
      </Col>
    </Row>
  );
};

export default HomeScreen;
