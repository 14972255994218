import axios from "axios";
import { getStorageItem } from "../helpers/local-storage";


export const baseURL = process.env.REACT_APP_BASE_URL_BACKEND;
const instance = axios.create({ baseURL });

instance.interceptors.request.use(config => {
  const tokens = getStorageItem('KC_TOKEN');
  const language = getStorageItem('i18nextLng') || 'es';
  if (tokens) {
    const token = JSON.parse(tokens).token;
    config.headers.Authorization = `Bearer ${token}`;
  } else config.headers.Authorization = '';
  config.headers['x-lang'] = language;
  return config;
}, error => Promise.reject(error));

export default instance;