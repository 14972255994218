import React, { useCallback, useEffect, useState, useRef } from "react";
import { Layout, Button, Typography, Dropdown, Space, Grid, Menu, Tooltip, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useKeycloak } from "@react-keycloak/web";
import { decodeJwt } from "jose";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { BaseNavbar } from "./BaseNavbar";
import { Link } from "react-router-dom";
import { LogoutIcon } from "../assets/icons/LogoutIcon";
import { LanguageIcon } from "../assets/icons/LanguageIcon";
import { UsersIcon } from "../assets/icons/UsersIcon";
import logo from "../assets/icons/logo-orson.svg";
import userApi from "../api/user";
import { Roles, ServiceType } from "../constants/CommonEnum";

import "./layout.css";


const { Content, Footer, Header, Sider } = Layout;
const { useBreakpoint } = Grid;
const { Text } = Typography;

export const AppLayout = ({ children }) => {

  const { xs, sm, lg } = useBreakpoint();
  const [navItems, setNavitems] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [services, setServices] = useState([]);
  const [templates, setTemplates] = useState([]);
  const { keycloak: { token, logout } } = useKeycloak();
  const { sub, name, realm_access } = decodeJwt(token);
  const { t, i18n } = useTranslation("translation", { keyPrefix: "common" });
  const { t: t2 } = useTranslation("translation", { keyPrefix: "menu" });
  const language = i18n.language.substring(0, 2) === 'en' ? t('languages.english') : t('languages.spanish');

  const langPickerItems = [
    {
      key: 'en',
      label: t('languages.english')
    },
    {
      key: 'es',
      label: t('languages.spanish')
    },
  ];



  useEffect(() => {
    handleLanguageChanged();
  }, []);


  const handleLanguageChanged = useCallback(async () => {
    const securedNavbar = filterSecureNav(BaseNavbar);
    const securedNavbarWithReport = await loadDynamicMenuItem(securedNavbar);
    setNavitems(securedNavbarWithReport);
  }, []);


  useEffect(() => {
    i18n.on('languageChanged', handleLanguageChanged);
    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [handleLanguageChanged]);


  useEffect(() => {
    if (xs || (sm && !lg)) setCollapsed(true);
    else setCollapsed(false);
  }, [xs, sm, lg]);


  const loadDynamicMenuItem = async (securedNavbar) => {
    let auxTemplates = templates;
    let auxServices = services;
    if (!auxTemplates.length) {
      try {
        const { data: menu } = await userApi.getServicesAndTemplatesForMenu(sub);

        setTemplates(menu?.esrsTemplates);
        setServices(menu?.services);

        auxTemplates = menu?.esrsTemplates || [];
        auxServices = menu?.services || [];
      } catch (error) {
        notification.error({
          duration: 3,
          message: t("notification.errorGettingRegisters"),
        });
      }
    }

    const userRoles = realm_access.roles;
    const baseNavbarWithReports = securedNavbar.map((item) => {
      if (item.key === 'report' && auxServices?.some((serv) => serv === ServiceType.ESRS))
        return ({
          ...item,
          children: auxTemplates.map(template => {
            if (template.type === 'einf') return ({
              key: template.id,
              label: (
                <Link className="nav-link" to={(userRoles.includes(Roles.ADMIN_CROWE) || userRoles.includes(Roles.SUPERADMIN)) ? `/einf` : `/my-einf`}>
                  {template.name[i18n.language?.substring(0, 2) || "es"]}
                </Link>
              ),
            });

            if (template.type === 'esrs') return ({
              key: template.id,
              label: (
                <Link className="nav-link" to={(userRoles.includes(Roles.ADMIN_CROWE) || userRoles.includes(Roles.SUPERADMIN)) ? `/sr/${template.id}` : `/my-sr/${template.id}`}>
                  {template.name[template.langCodes?.find((l) => l === i18n.language.substring(0, 2)) || template.langCodes[0]]}
                </Link>
              ),
            })
          })
        })

      if (item.key === 'report' && !auxServices?.some((serv) => serv === ServiceType.ESRS))
        return; //si no tiene el servicio contratado no se muestra en el menu

      if (['DM_REPORT_SCREEN', 'MY_DM_REPORT_SCREEN'].includes(item.key)
        && !auxServices?.some((serv) => serv === ServiceType.DM))
        return; //si no tiene el servicio contratado no se muestra en el menu
      else
        return item;
    });

    return baseNavbarWithReports;
  };

  const filterSecureNav = items => {
    const filteredItems = [];
    if (items)
      for (const item of items) {
        const newItem = { ...item };

        if (item.roles && !item.roles.some((role) => realm_access.roles.includes(role))) continue;
        if (!item.children) {
          if (typeof item.label === "string") newItem['label'] = t2(item.label);
          else newItem['label'] = React.cloneElement(item.label, [], t2(item.label?.props?.children));
          filteredItems.push(newItem);
        } else {
          if (typeof item.label === "string") newItem['label'] = t2(item.label);
          else newItem['label'] = React.cloneElement(item.label, [], t2(item.label?.props?.children));
          const filteredChildren = filterSecureNav(item.children);
          filteredItems.push({ ...newItem, children: filteredChildren });
        }

      }
    return filteredItems;
  };

  return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={value => setCollapsed(value)}
        style={{
          textAlign: 'center',
          paddingTop: "20px",
          borderRight: '6px solid #E7B618',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          zIndex: 1000,
        }}
        theme="light"
        width={220}
        trigger={
          <div
            style={{
              display: "block",
              backgroundColor: '#EFF1F4',
              borderRight: '6px solid #E7B618',
              fontSize: '20px',
            }}>{collapsed ? <RightOutlined /> : <LeftOutlined />}
          </div>
        }>
        <Link to="/"><img src={logo} style={{ marginBottom: '20px' }} /></Link>
        <Menu
          selectedKeys={[]}
          items={navItems}
          mode="vertical"
          style={{
            backgroundColor: '#EFF1F4',
          }}
        />
        <Tooltip title={t('logout')}>
          <Button
            onClick={() => logout()}
            type="primary"
            style={{
              position: 'absolute',
              left: '10px',
              right: '10px',
              bottom: '50px'
            }}>
            {!collapsed && t('logout')}
            <LogoutIcon style={{ width: '14px', ...(!collapsed && { marginLeft: '20px' }) }} />
          </Button>
        </Tooltip>
      </Sider>
      <Layout style={{ minHeight: "100vh", overflowY: "auto" }}>
        <Header
          style={{
            backgroundColor: 'white',
            position: 'fixed',
            zIndex: '900',
            marginLeft: `${collapsed ? '80' : '220'}px`,
            width: `calc(100% - ${collapsed ? '80' : '220'}px)`,
          }}>
          <Space wrap style={{ width: '100%', justifyContent: 'end' }}>
            <Dropdown
              menu={{
                items: langPickerItems,
                selectable: true,
                defaultSelectedKeys: i18n.language.substring(0, 2),
                onSelect: ({ key }) => i18n.changeLanguage(key)
              }}>
              <Button
                style={{ backgroundColor: '#18325E', color: 'white' }}
                icon={<LanguageIcon color="white" style={{ width: '14px' }} />}>
                {language}
              </Button>
            </Dropdown>
            <Text className="username">
              <UsersIcon color="white" style={{ marginRight: '5px' }} /> {name}
            </Text>
          </Space>
        </Header>
        <Content
          style={{
            marginLeft: `${collapsed ? '80' : '220'}px`,
            width: `calc(100% - ${collapsed ? '80' : '220'}px)`,
            padding: "64px 24px 24px 24px",
            minHeight: "calc(100vh - 136px)",
            width: `calc(100% - ${collapsed ? '80' : '220'}px)`,
            backgroundColor: 'white',
          }}>
          {children}
        </Content>
        <Footer
          style={{
            marginLeft: `${collapsed ? '80' : '220'}px`,
            width: `calc(100% - ${collapsed ? '80' : '220'}px)`,
            height: "48px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: 'white',
          }}>
          Orson© 2024
        </Footer>
      </Layout>
    </Layout>
  );
}
