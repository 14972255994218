import React from "react";
import Icon from "@ant-design/icons";


export const LanguageIcon = props => (
  <Icon
    {...props}
    component={() =>
      <svg viewBox="0 0 370 305">
        <path style={{ fill: props.color }} d="M 274.819 62.364 C 291.144 62.364 307.043 62.364 322.941 62.364 C 329.566 62.098 336.159 63.408 342.178 66.187 C 357.286 72.659 367.043 87.558 366.937 103.993 C 367.361 143.983 366.937 184.033 366.937 224.023 C 366.416 246.87 347.914 265.211 325.066 265.531 L 309.287 265.531 L 309.287 303.579 C 294.906 295.387 280.706 288.529 267.841 279.731 C 252.251 268.632 233.191 263.507 214.136 265.288 C 197.509 266.563 180.761 265.653 164.074 265.288 C 140.304 265.494 120.992 246.154 121.232 222.385 L 121.232 208.428 L 60.911 242.834 L 60.911 204.908 L 46.409 204.908 C 22.136 205.506 2.302 185.672 2.899 161.398 C 2.899 122.764 2.899 84.069 2.899 45.313 C 2.084 20.823 22.099 0.726 46.591 1.439 C 108.043 1.439 169.495 1.439 230.946 1.439 C 255.395 1.021 275.304 20.985 274.819 45.434 C 274.881 50.652 274.819 56.356 274.819 62.364 Z M 81.787 183.729 L 81.787 206.426 C 93.924 199.386 106.06 192.772 117.286 186.036 C 120.052 184.441 123.196 183.624 126.39 183.67 C 161.099 183.67 195.811 183.67 230.522 183.67 C 243.46 184.637 254.219 173.85 253.216 160.913 C 253.216 122.602 253.216 84.291 253.216 45.98 C 254.233 32.831 243.24 21.897 230.096 22.98 L 47.44 22.98 C 34.323 21.714 23.279 32.672 24.442 45.797 L 24.442 160.61 C 23.21 173.73 34.195 184.746 47.319 183.548 L 81.787 183.729 Z M 142.47 205.091 L 142.47 211.705 C 142.47 239.438 147.628 244.657 175.117 244.657 C 197.328 244.657 219.477 244.657 241.87 244.657 C 246.583 244.567 251.223 245.853 255.219 248.357 C 264.686 254.123 274.516 259.463 284.226 264.924 C 285.439 265.591 286.53 267.048 288.594 265.834 L 288.594 244.474 C 300.246 244.474 311.594 244.474 322.941 244.474 C 335.86 245.28 346.521 234.505 345.576 221.597 L 345.576 106.298 C 346.623 93.28 335.782 82.409 322.759 83.422 L 274.819 83.422 L 274.819 90.338 C 274.819 114.067 274.819 137.794 274.819 161.46 C 275.167 185.703 255.369 205.417 231.127 204.969 L 142.592 204.969 L 142.47 205.091 Z" />
        <path style={{ fill: props.color }} d="M 120.138 153.813 L 65.523 153.813 L 65.523 52.837 L 120.138 52.837 L 120.138 73.652 L 87.37 73.652 L 87.37 92.342 L 117.712 92.342 L 117.712 113.824 L 87.37 113.824 L 87.37 132.575 L 120.26 132.575 L 120.138 153.813 Z" />
        <path style={{ fill: props.color }} d="M 205.519 62.788 L 192.17 78.689 C 187.934 76.333 183.374 74.616 178.638 73.59 C 176.187 73.357 173.717 73.459 171.295 73.894 C 167.816 74.047 164.887 76.55 164.194 79.962 C 163.467 84.332 166.864 85.242 169.838 86.455 C 176.635 89.186 183.553 91.735 190.35 94.647 C 199.991 97.958 207.545 105.559 210.799 115.218 C 214.677 130.331 206.462 145.919 191.805 151.264 C 173.853 157.703 153.789 152.615 141.075 138.401 L 156.548 124.443 C 161.121 128.906 167.02 131.76 173.358 132.575 C 176.625 133.272 180.019 133.082 183.188 132.028 C 187.134 130.913 190.027 127.54 190.531 123.472 C 190.895 119.224 187.558 116.978 184.463 115.522 C 177.909 112.671 171.173 110.244 164.437 107.756 C 152.846 103.447 144.412 96.225 143.016 83.179 C 141.813 69.152 151.222 56.416 164.984 53.444 C 179.213 49.931 194.264 53.402 205.519 62.788 Z" />
      </svg>
    }
  />
);
