import React from "react";
import { Result } from "antd";
import { useTranslation } from "react-i18next";

const ForbiddenScreen = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "forbiddenScreen",
  });

  return (
    <>
      <Result status="403" title={t("title")} subTitle={t("subtitle")} />
    </>
  );
};

export default ForbiddenScreen;
